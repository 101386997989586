import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import Layout from './Layout'
import Seo from './Seo'
import BackToTopButton from './BackToTopButton'
import Link from './LinkWrapper'

const SculptingChallengeQuery = graphql`
  query SculptingChallengeQuery {
    safariAnimals: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-חיות-הספארי-אריה-ג׳ירפה-קוף-פיל-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const SculptingChallengeTemplate = ({ pathname, search, data, images }) => {
  const [iframeUrl, setIframeUrl] = useState(null)
  useEffect(() => {
    setIframeUrl(
      `https://www.karinnarts.co.il/forms/?ff_landing=25&embedded=1&challenge_name=${encodeURIComponent(
        data.challenge.name.replaceAll(' ', '-')
      )}` +
        (search
          ? '&' +
            search
              .slice(1)
              .split('&')
              .filter(
                (item) =>
                  item.split('=')[0] !== 'ns_url' &&
                  item.split('=')[0] !== 'mid'
              )
              .join('&')
          : '')
    )
  }, [])
  const { safariAnimals, survival } = images

  const BULLETS_IMAGE = [data.image, safariAnimals]

  return (
    <>
      <BackToTopButton selectorId='sculpting-challenge' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{data.headerAcademy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {data.header}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column'>
                <h3 className='is-size-5 has-text-nude'>{data.subtitle}</h3>
                <h1 className='is-size-3'>{data.challenge.name}</h1>
                <h2 className='is-size-4'>{data.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.bullets.map((bullet, index) => (
        <div
          id={index === 1 ? 'sculpting-challenge' : undefined}
          key={index}
          className={clsx('hero', {})}
        >
          <div className='hero-body'>
            <div className='container'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <GatsbyImage
                    image={getImage(BULLETS_IMAGE[index])}
                    title={bullet.image.title}
                    alt={bullet.image.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(BULLETS_IMAGE[index]).width,
                      margin: '0 auto' // used to center the image
                    }}
                    loading='eager'
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {bullet.image.title}
                    </p>
                  </div>
                </div>
                <div
                  className={clsx(
                    'column is-full-mobile is-10-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 0
                    }
                  )}
                >
                  <div className='content'>
                    <h3>{bullet.title}</h3>
                    <p>{bullet.description}</p>
                    <strong>{bullet.question}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <FontAwesomeIcon
              icon={faCalendarDays}
              className='has-text-nude is-margin-bottom-4'
              size='lg'
            />
            <div className='is-margin-bottom-4'>
              <h3>{data.challenge.when.title}</h3>
            </div>
            <p>{data.challenge.when.description}</p>
            <p className='is-italic'>{data.challenge.when.hint}</p>
          </div>
        </div>
      </div>
      {iframeUrl && (
        <div id='registration' className='hero'>
          <div className='hero-body is-padding-bottom-0'>
            <div className='container content has-text-centered'>
              <h3>{data.registration.title}</h3>
              <p>{data.registration.hint}</p>
              <div className='columns is-centered'>
                <div className='column is-full-mobile is-10-tablet is-6-desktop'>
                  <iframe
                    id='fluentform'
                    width='100%'
                    height='100%'
                    loading='lazy'
                    style={{ minHeight: '400px', width: '100%' }}
                    frameBorder='0'
                    onLoad={(event) => {
                      event.target.style.height =
                        event.target.contentWindow.document.body.scrollHeight +
                        'px'
                    }}
                    src={iframeUrl}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-mobile is-multiline is-centered'>
              <div className='column content is-margin-bottom-0 is-full-mobile is-10-tablet is-6-desktop'>
                <h3>{data.about.title}</h3>
                <p>{data.about.description}</p>
              </div>
              <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen is-order-3-touch'>
                <GatsbyImage
                  image={getImage(survival)}
                  title={data.about.mainImage.title}
                  alt={data.about.mainImage.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(survival).width,
                    margin: '0 auto' // used to center the image
                  }}
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {data.about.mainImage.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const SculptingChallenge = ({
  pageContext: data,
  location: { pathname, search }
}) => {
  const images = useStaticQuery(SculptingChallengeQuery)
  return (
    <Layout>
      <Seo
        metadata={data.challenge.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.hero),
          alt: data.challenge.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: data.headerAcademy, path: '/academy/' },
            {
              name: data.header,
              path: `/academy/challenges/${data.challenge.name.replaceAll(
                ' ',
                '-'
              )}`
            }
          ]
        }}
      />
      <SculptingChallengeTemplate
        pathname={pathname}
        search={search}
        data={data}
        images={images}
      />
    </Layout>
  )
}

export default SculptingChallenge
